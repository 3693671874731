var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"content"}},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-auto ml-auto d-flex"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.addLicenceConfig}},[_vm._v(" "+_vm._s(_vm.$t('global.ajouter'))+" "),_c('font-awesome-icon',{staticClass:"ml-1",attrs:{"icon":['fal', 'plus']}})],1)],1)]),_vm._l((_vm.licence_config),function(appli,index){return _c('div',{key:index,staticClass:"row mt-2"},[_c('div',{staticClass:"col-6"},[_c('e-select',{attrs:{"track-by":"licenceconfigtype_key","options":_vm.all_config,"searchable":true,"allow-empty":false,"placeholder":_vm.$t('global.appli_type')},on:{"select":function($event){return _vm.handleNewConfig($event, appli)}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(_vm.$t('licence_config.'+option.licenceconfigtype_label)))]}},{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(_vm.$t('licence_config.'+option.licenceconfigtype_label)))]}}],null,true),model:{value:(appli.type),callback:function ($$v) {_vm.$set(appli, "type", $$v)},expression:"appli.type"}},[_c('template',{slot:"noOptions"},[_vm._v(_vm._s(_vm.$t('global.list_empty')))])],2)],1),_c('div',{staticClass:"col-6"},[(appli.type && appli.type.licenceconfigtype_type == 'TXT')?_c('b-form-input',{attrs:{"placeholder":_vm.$t('global.licence_key')},model:{value:(appli.value),callback:function ($$v) {_vm.$set(appli, "value", $$v)},expression:"appli.value"}}):(appli.type && appli.type.licenceconfigtype_type == 'BOO')?_c('b-form-checkbox',{attrs:{"name":"check-button","switch":""},model:{value:(appli.value),callback:function ($$v) {_vm.$set(appli, "value", $$v)},expression:"appli.value"}}):(appli.type && (appli.type.licenceconfigtype_type == 'SLCT'))?_c('e-select',{attrs:{"options":appli.type.values,"searchable":true,"allow-empty":false,"placeholder":_vm.$t('global.appli_type')},on:{"update:options":function($event){return _vm.$set(appli.type, "values", $event)}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(_vm.$t(option)))]}},{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(_vm.$t(option)))]}}],null,true),model:{value:(appli.value),callback:function ($$v) {_vm.$set(appli, "value", $$v)},expression:"appli.value"}},[_c('template',{slot:"noOptions"},[_vm._v(_vm._s(_vm.$t('global.list_empty')))])],2):(appli.type && appli.type.licenceconfigtype_type == 'DATE')?_c('div',[_c('e-datepicker',{model:{value:(appli.value),callback:function ($$v) {_vm.$set(appli, "value", $$v)},expression:"appli.value"}})],1):_vm._e()],1)])}),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-12 text-center"},[(_vm.licence_config.length > 0)?_c('b-button',{attrs:{"variant":"primary","rounded-pill":""},on:{"click":_vm.saveConfig}},[(_vm.processing)?_c('font-awesome-icon',{attrs:{"icon":['fas', 'spinner'],"pulse":""}}):_c('font-awesome-icon',{attrs:{"icon":['fal', 'save']}}),_vm._v(" "+_vm._s(_vm.$t('global.enregistrer')))],1):_vm._e()],1)])],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }