<template>
    <div id="content">
        <div class="container-fluid">
            <div class="box">
            	<div class="row">
					<div class="col-auto ml-auto d-flex">
                		<b-button variant="primary" @click="addLicenceConfig">
                			{{ $t('global.ajouter') }} <font-awesome-icon :icon="['fal', 'plus']" class="ml-1"/>
		                </b-button>
                	</div>
                </div>
            	<div class="row mt-2" v-for="(appli, index) in licence_config" :key="index">
            		<div class="col-6">
                		<e-select
                            v-model="appli.type"
                            track-by="licenceconfigtype_key"
                            :options="all_config"
                            :searchable="true"
                            :allow-empty="false"
							:placeholder="$t('global.appli_type')"
                            @select="handleNewConfig($event, appli)"
                        >
                            <template slot="option" slot-scope="{ option }">{{ $t('licence_config.'+option.licenceconfigtype_label) }}</template>
                            <template slot="singleLabel" slot-scope="{ option }">{{ $t('licence_config.'+option.licenceconfigtype_label) }}</template>
                            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                        </e-select>
                    </div>

                    <div class="col-6">
						<b-form-input
                            v-if="appli.type && appli.type.licenceconfigtype_type == 'TXT'"
							v-model="appli.value"
							:placeholder="$t('global.licence_key')"
						></b-form-input>
                        <b-form-checkbox 
                            v-else-if="appli.type && appli.type.licenceconfigtype_type == 'BOO'"
                            name="check-button"
                            switch 
                            v-model="appli.value"
                        />
                        <e-select
                            v-else-if="appli.type && (appli.type.licenceconfigtype_type == 'SLCT')"
                            v-model="appli.value"
                            :options.sync="appli.type.values"
                            :searchable="true"
                            :allow-empty="false"
							:placeholder="$t('global.appli_type')"
                        >
                            <template slot="option" slot-scope="{ option }">{{ $t(option) }}</template>
                            <template slot="singleLabel" slot-scope="{ option }">{{ $t(option) }}</template>
                            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                        </e-select>
                        <div v-else-if="appli.type && appli.type.licenceconfigtype_type == 'DATE'">
                            <e-datepicker 
                                v-model="appli.value" 
                            />
                        </div>
					</div>
            	</div>
                <div class="row mt-2">
                    <div class="col-12 text-center">
                        <b-button v-if="licence_config.length > 0" variant="primary" rounded-pill @click="saveConfig"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /> {{ $t('global.enregistrer') }}</b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import User from '@/mixins/User'
import LicenceConfigType from "@/mixins/LicenceConfigType.js"
import Common from '@/assets/js/common'

export default {
    name: 'configSettings',
    mixins: [User, LicenceConfigType],
   	data () {
		return {
			all_config: [],
			licence_config: [],
            processing: false,
            season: []
		}
	},
    created() {
        this.init_component()
    },
    methods: {
        async init_component() {
            this.licence_config = []
            this.all_config = await this.getAllConfig()
            this.all_config.map(config => config.values = config.licenceconfigtype_values.split(','))
        	const config = await this.getLicenceConfig()


            await Common.asyncForEach(config, async (app) => {
                let value = app.licenceconfig_value
                let type = this.all_config.find(type => app.licenceconfig_key == type.licenceconfigtype_key)
                if(type) {
                    if(type.licenceconfigtype_type == 'BOO') {
                        value = (value == "1")
                    }
                    if(type.licenceconfigtype_type == 'DATE') {
                        if(value.length > 0){
                            value = new Date(value)
                        } else {
                            value = new Date()
                        }
                    }
                    if(type.licenceconfigtype_type == 'SLCT') {
                        type = await this.InitConfig(type)
                    }

                    this.licence_config.push({ type, value })
                }
		    })
        },

        addLicenceConfig() {
        	this.licence_config.push({
        		type: null,
        		value: null
        	})
        },

        async InitConfig(app) {
            app.values = await this[app.licenceconfigtype_values]()
            return app
        },

        async handleNewConfig(event, appli) {

            let res = await this[event.licenceconfigtype_values]()
            event.values = res
            appli.value = event.values[0]
        },

        async saveConfig() {
            this.processing = true
            const config = this.licence_config.map(app => {
                return {
                    type: app.type.licenceconfigtype_key,
                    value: app.value
                }
            })
            await this.saveLicenceConfig(config)
            this.processing = false
            this.init_component()
        }
    }
};
</script>